<template>
  <div>
    <header-slot>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'correspondence-letters-pending' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Pending
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'correspondence-letters-send' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Sent
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import LettersService from "@/views/correspondence/views/letters/services/letters.services";
export default {
  data() {
    return {
      //
    };
  },
  methods: {
    //
  }
};
</script>

<style>
</style>